import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { LocaleConfig } from '../core'
import {
    flattenEdgesNodes,
    getMatchingImageSrc,
    ImageFilesData,
} from '../core/graphql'

interface PeopleProps {
    locale: LocaleConfig
}

export interface PeopleData {
    allPeopleYaml: {
        edges: Array<{
            node: {
                id: string
                name: { ja: string; en: string }
                furigana: string
                position: { ja: string; en: string }
                image: string
            }
        }>
    }
    allFile: ImageFilesData
}

export const People = ({ locale }: PeopleProps) => {
    const data = useStaticQuery<PeopleData>(graphql`
        query PeopleQuery {
            allPeopleYaml {
                edges {
                    node {
                        id: yamlId
                        name {
                            ja
                            en
                        }
                        furigana
                        position {
                            en
                            ja
                        }
                        image
                    }
                }
            }
            allFile(
                filter: { relativePath: { glob: "company/people/*.png" } }
            ) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                height: 340
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const images = flattenEdgesNodes(data.allFile)
    const people = flattenEdgesNodes(data.allPeopleYaml).map((node) => ({
        id: node.id,
        name: node.name[locale.locale],
        furigana: node.furigana,
        position: node.position[locale.locale],
        image: getMatchingImageSrc(images, node.image)!,
    }))

    return (
        <StyledPeople>
            {people.map((person) => (
                <Person key={person.id}>
                    <AvatarWrapper>
                        <Avatar
                            image={person.image}
                            className={`person--${person.id}`}
                            data-testid="company-person-avatar"
                        />
                    </AvatarWrapper>
                    <Name>
                        {person.name}
                        {locale.locale === 'ja' && <div>{person.furigana}</div>}
                    </Name>
                    {person.position}
                </Person>
            ))}
        </StyledPeople>
    )
}

const StyledPeople = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        flex-wrap: wrap;
    }

    @media ${({ theme }) => theme.breakpoints.tablet_up} {
        transform: translateX(-30px) scale(0.8);
    }
`
const Person = styled.div`
    width: 30%;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 21px;
    text-align: center;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        margin-bottom: 32px;
        font-size: 10px;
        line-height: 13px;
    }
`

const AvatarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 315px;
    height: 350px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        height: 245px;
    }

    .person--arnar {
        max-width: 305px;
        max-height: 306px;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            max-width: 215px;
            max-height: 215px;
        }
    }

    .person--eythor {
        max-width: 244px;
        max-height: 339px;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            max-width: 170px;
            max-height: 237px;
        }
    }

    .person--furui {
        max-width: 302px;
        max-height: 267px;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            max-width: 211px;
            max-height: 187px;
        }
    }
`

const Avatar = styled.div<{ image: string }>`
    width: 100%;
    height: 100%;
    background-image: url('${({ image }) => image}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`

const Name = styled.h3`
    font-size: 22px;
    line-height: 33px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 14px;
        line-height: 21px;
        margin: 0;
    }
`
