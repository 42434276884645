import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container } from '../core'
import {
    flattenEdgesNodes,
    ImageFilesData,
    getImageFallbackSrc,
} from '../core/graphql'

interface PeopleProps {
    content: string
}

export interface CompanyProfileData {
    allFile: ImageFilesData
}

export const CompanyProfile = ({ content }: PeopleProps) => {
    const data = useStaticQuery<CompanyProfileData>(graphql`
        query CompanyProfileQuery {
            allFile(filter: { relativePath: { glob: "company/*.png" } }) {
                edges {
                    node {
                        name
                        extension
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 224
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    `)

    const logos = flattenEdgesNodes(data.allFile).reduce(
        (logos, node) => ({
            ...logos,
            [node.name]: getImageFallbackSrc(node),
        }),
        {}
    )

    return (
        <Container>
            <CompanyProfileData
                logos={logos}
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        </Container>
    )
}

type logos = Record<string, string>

const CompanyProfileData = styled.div<{ logos: logos }>`
    font-size: 14px;
    line-height: 21px;

    .Logo {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &.pmark {
            width: 110px;
            height: 110px;
            background-image: url('${({ logos }) => logos.pmark}');
        }

        &.eyrir {
            width: 224px;
            height: 76px;
            background-image: url('${({ logos }) => logos.eyrir}');

            @media ${({ theme }) => theme.breakpoints.phone_only} {
                width: 183px;
                height: 62px;
            }
        }

        &.nyskopunarsjodur {
            width: 135px;
            height: 81px;
            background-image: url('${({ logos }) => logos.nyskopunarsjodur}');

            @media ${({ theme }) => theme.breakpoints.phone_only} {
                width: 110px;
                height: 66px;
            }
        }
    }

    .Investors {
        display: flex;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            flex-wrap: wrap;
            margin-bottom: 24px;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        line-height: 24px;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            table-layout: fixed;
        }
    }

    tbody {
        vertical-align: top;
    }

    th {
        width: 248px;
        padding-right: 24px;
        text-align: center;
        vertical-align: top;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            width: 106px;
            padding-right: 16px;
            text-align: left;
        }
    }

    th,
    td {
        padding-bottom: 32px;
    }
`
