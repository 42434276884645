import React, { useMemo, useState } from 'react'
import {
    Layout,
    SEO,
    useTranslations,
    LocaleConfig,
    ContentSpacer,
    TextBlock,
    SectionSpacer,
} from '../modules/core'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { People } from '../modules/company/People'
import { Switch } from '../modules/ui/Switch'
import { CompanyProfile } from '../modules/company/CompanyProfile'

type CompanySection = 'company' | 'missionStatement' | 'partnership' | 'profile'

export interface CompanyData {
    allMarkdownRemark: {
        edges: Array<{
            node: {
                frontmatter: {
                    section: CompanySection
                    title: string
                    locale: LocaleConfig['locale']
                }
                html: string
            }
        }>
    }
}

type SectionData = Record<
    LocaleConfig['locale'],
    Record<
        CompanySection,
        {
            section: CompanySection
            title: string
            locale: LocaleConfig['locale']
            content: string
        }
    >
>

export const useLocaleSections = (data: CompanyData) =>
    useMemo(
        () =>
            data.allMarkdownRemark.edges
                .map((edge) => ({
                    section: edge.node.frontmatter.section,
                    title: edge.node.frontmatter.title,
                    locale: edge.node.frontmatter.locale,
                    content: edge.node.html,
                }))
                .reduce(
                    (sectionsByLocale, section) => ({
                        ...sectionsByLocale,
                        [section.locale]: {
                            ...sectionsByLocale[section.locale],
                            [section.section]: section,
                        },
                    }),
                    {
                        ja: {},
                        en: {},
                    }
                ) as SectionData,
        [data]
    )

interface CompanyPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
}

export const CompanyPage = ({ pageContext, path }: CompanyPageProps) => {
    const [locale, setLocale] = useState(pageContext.localeConfig)

    const data = useStaticQuery<CompanyData>(graphql`
        query CompanyQuery {
            allMarkdownRemark(
                filter: { frontmatter: { page: { eq: "company" } } }
            ) {
                edges {
                    node {
                        frontmatter {
                            section
                            title
                            locale
                        }
                        html
                    }
                }
            }
        }
    `)

    const sectionsByLocale = useLocaleSections(data)

    const toggleLocale = () =>
        setLocale((prev) => ({
            ...prev,
            locale: prev.locale === 'ja' ? 'en' : 'ja',
        }))

    const { translate } = useTranslations(locale.locale)

    const checked = locale.locale === 'en'

    return (
        <Layout locale={pageContext.localeConfig} path={path}>
            <SEO title={translate('page_company')} />
            <Profile>
                <InnerContainer>
                    <TitleWrapper>
                        <Title>
                            {sectionsByLocale[locale.locale].company.title}
                        </Title>
                        <Switch
                            checked={checked}
                            onChange={toggleLocale}
                            offLabel="日本語"
                            onLabel="English"
                        />
                    </TitleWrapper>
                    <ContentSpacer size="large" />
                    <People locale={locale} />
                    <ContentSpacer size="large" />
                    <Points>
                        <Point>
                            <PointTitle>
                                {
                                    sectionsByLocale[locale.locale]
                                        .missionStatement.title
                                }
                            </PointTitle>
                            <ContentSpacer />
                            <PointContent
                                dangerouslySetInnerHTML={{
                                    __html: sectionsByLocale[locale.locale]
                                        .missionStatement.content,
                                }}
                            />
                        </Point>
                        <Point>
                            <PointTitle>
                                {
                                    sectionsByLocale[locale.locale].partnership
                                        .title
                                }
                            </PointTitle>
                            <ContentSpacer />
                            <PointContent
                                dangerouslySetInnerHTML={{
                                    __html: sectionsByLocale[locale.locale]
                                        .partnership.content,
                                }}
                            />
                        </Point>
                    </Points>
                </InnerContainer>
            </Profile>
            <SectionSpacer />
            <CompanyProfile
                content={sectionsByLocale[locale.locale].profile.content}
            />
        </Layout>
    )
}

export default CompanyPage

const Profile = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.accent};
    padding-bottom: 50px;
`

const InnerContainer = styled.div`
    width: 100%;
    max-width: 960px;
    flex-shrink: 0;
`

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        flex-direction: column;
    }

    @media ${({ theme }) => theme.breakpoints.tablet_up} {
        padding-right: 24px;
    }
`

const Title = styled.h1`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 960px;
    margin: 0;
    padding: 0;
    font-size: 34px;
    font-family: ${({ theme }) => theme.typography.bolderFontFamily};
    font-weight: 400;
    height: 160px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        text-align: center;
        font-size: 24px;
        padding: 0 12px;
        height: 72px;
    }

    @media ${({ theme }) => theme.breakpoints.tablet_up} {
        padding: 0 24px;
    }
`

const Points = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        flex-direction: column;
        padding: 0 12px;
    }

    @media ${({ theme }) => theme.breakpoints.tablet_up} {
        padding: 0 24px;
    }
`

const Point = styled.div`
    width: 48%;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 100%;
        margin-bottom: 24px;
    }
`

const PointTitle = styled.h3`
    font-size: 26px;
    line-height: 39px;
    margin: 8px 0;
    white-space: pre-wrap;
    ${({ theme }) => theme.typography_presets.bold}

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 20px;
        line-height: 30px;
    }
`

const PointContent = styled(TextBlock)`
    line-height: 24px;
`
