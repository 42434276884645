import React, { useMemo } from 'react'
import styled from 'styled-components'

interface SwitchProps {
    checked?: boolean
    disabled?: boolean
    onLabel: string
    offLabel: string
    onChange: () => void
}

let lastSwitchId = 0

export const Switch = ({
    checked = false,
    disabled = false,
    offLabel,
    onLabel,
    onChange,
}: SwitchProps) => {
    // We need to generate a unique id for each switch otherwise the underlying label does not
    // toggle the correct switch.
    const switchId = useMemo(() => `_switch-${lastSwitchId++}`, [])

    return (
        <>
            <Input
                id={switchId}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <Label htmlFor={switchId}>
                <Toggle checked={checked} />
                <LabelText checked={checked}>
                    <span>{offLabel}</span>
                    <span>{onLabel}</span>
                </LabelText>
            </Label>
        </>
    )
}

const Input = styled.input`
    // We want to visually hide the underlying checkbox input component
    // but not hide it via setting display: none or visibility: hidden
    // for two reasons:
    // 1) if we hide the component it cannot take focus
    // 2) screenreaders would ignore it
    position: absolute;
    margin-left: -99999px;
    width: 0;
    height: 0;
`

const Label = styled.label`
    position: relative;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    border-radius: 24px;
    cursor: pointer;
    text-decoration: none;
    user-select: none;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        display: flex;
        width: 232px;
    }
`

const Toggle = styled.div<{ checked: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 192px;
    height: 100%;
    background: ${({ theme }) => theme.colors.invertedBackground};
    border-radius: 24px;
    z-index: 0;
    transform: translateX(${({ checked }) => (checked ? '158px' : '0')});
    transition: transform 300ms ease-in-out;
    will-change: transform;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 128px;
        transform: translateX(${({ checked }) => (checked ? '104px' : '0')});
    }
`

const LabelText = styled.div<{ checked: boolean }>`
    position: relative;
    width: 350px;
    height: 100%;

    > span {
        position: absolute;
        top: 50%;
        width: 192px;
        text-align: center;
        font-weight: 400;
        background-color: transparent;
        transition: color 300ms ease-in-out;
        z-index: 1;
        transform: translateY(-50%);

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            width: 128px;
        }

        &:nth-child(1) {
            left: 0;
            color: ${({ theme, checked }) =>
                checked ? theme.colors.text : theme.colors.invertedText};
        }

        &:nth-child(2) {
            right: 0;
            color: ${({ theme, checked }) =>
                checked ? theme.colors.invertedText : theme.colors.text};
        }
    }
`
